.text{
  background: -webkit-linear-gradient(45deg,#3acfd5, #6475e4);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.ttext{background: -webkit-linear-gradient(45deg,#3acfd5, #6475e4);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;}

.text:hover{
  -webkit-text-fill-color: black;
}

.button:hover{
  background: linear-gradient(to right, #3acfd5 44%, #6475e4);
}
.border-type-1 {
  left: 40px;
  position: relative;
  padding: 18px 18px 18px 0;
  background: linear-gradient(to right, #3acfd5, #3a4ed5);
  border-left-width: -10px;
  border-radius: 0 180px 180px 0;
  width: 100%;
  height: 360px;
  z-index: 100;
}
.inner-1 {
  z-index: 101;
  background: black;
  height: 324px;
  border-radius: 0 180px 180px 0;
}
.border-type-2 {
  margin-top: -18px;
  left: -140px;
  position: relative;
  padding: 18px 0 18px 18px;
  background: linear-gradient(to right, #3acfd5 44%, #3a4ed5);
  border-right-width: 0;
  border-radius: 180px 0 0 180px;
  width: 100%;
  height: 360px;
  z-index: 100;
}
.inner-2 {
  z-index: 100;
  background: black;
  height: 324px;
  border-radius: 180px 0px 0px 180px;
}
/* .border-type-2 {
  margin-top: -18px;
  margin-left: -36px;
  position: relative;
  width: 59%;
  border: double 18px transparent;
  border-right-width: 0;
  height: 180px;
  border-radius: 90px 0px 0px 90px;
  z-index: 99;
} */
.border-type-3 {
  margin-top: -18px;
  left: 40px;
  position: relative;
  padding: 18px 18px 18px 0;
  background: linear-gradient(to right, #3acfd5, #3a4ed5);
  border-left-width: 0;
  border-radius: 0 180px 180px 0;
  width: 100%;
  height: 360px;
  z-index: 100;
}
.inner-3 {
  z-index: 101;
  background: black;
  height: 324px;
  border-radius: 0 180px 180px 0;
}
.border-type-4 {
  margin-top: -18px;
  left: -140px;
  position: relative;
  padding: 18px 0 18px 18px;
  background: linear-gradient(to right, #3acfd5 44%, #3a4ed5);
  border-right-width: 0;
  border-radius: 180px 0 0 180px;
  width: 100%;
  height: 360px;
  z-index: 100;
}
.inner-4 {
  z-index: 100;
  background: black;
  height: 324px;
  border-radius: 180px 0px 0px 180px;
}
.border-type-5 {
  margin-top: -18px;
  left: 40px;
  position: relative;
  padding: 18px 18px 18px 0;
  background: linear-gradient(to right, #3acfd5, #3a4ed5);
  border-left-width: 0;
  border-radius: 0 180px 180px 0;
  width: 100%;
  height: 360px;
  z-index: 100;
}
.inner-5 {
  z-index: 101;
  background: black;
  height: 324px;
  border-radius: 0 180px 180px 0;
}
.border-type-6 {
  margin-top: -18px;
  left: -140px;
  position: relative;
  padding: 18px 0 18px 18px;
  background: linear-gradient(to right, #3acfd5 44%, #3a4ed5);
  border-right-width: 0;
  border-radius: 180px 0 0 180px;
  width: 100%;
  height: 360px;
  z-index: 100;
}
.inner-6 {
  z-index: 100;
  background: black;
  height: 324px;
  border-radius: 180px 0px 0px 180px;
}
.border-type-7 {
  margin-top: -18px;
  left: 40px;
  position: relative;
  padding: 18px 18px 18px 0;
  background: linear-gradient(to right, #3acfd5, #3a4ed5);
  border-left-width: 0;
  border-radius: 0 180px 180px 0;
  width: 100%;
  height: 360px;
  z-index: 100;
}
.inner-7 {
  z-index: 101;
  background: black;
  height: 347px;
  border-radius: 0 180px 180px 0;
}

.verticle{
  opacity : 0;
  transition: 1s ease-in-out;
  filter: blur(10px);
  transform: translateY(100px);
}

.verticle.active{
  opacity: 1;
  transform: translateY(0px);
  filter:blur(0px);
  transition: 1s ease-in-out;
}

.left{
  opacity : 0;
  transition: 1s ease-in-out;
  filter: blur(10px);
  transform: translateX(-100px);
}

.left.active{
  opacity: 1;
  transform: translateX(0px);
  filter:blur(0px);
  transition: 1s ease-in-out;
}

.right{
  opacity : 0;
  transition: 1s ease-in-out;
  filter: blur(10px);
  transform: translateX(100px);
}

.right.active{
  opacity: 1;
  transform: translateX(0px);
  filter:blur(0px);
  transition: 1s ease-in-out;
}